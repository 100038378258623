import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faGitlab,
  faWordpress,
  faSoundcloud,
  faYoutube,
  faUnsplash,
  faLastfm,
  faApple,
  faSpotify,
  faBandcamp,
} from "@fortawesome/free-brands-svg-icons"
import { gsap, Power2 } from "gsap"

import "../scss/main.scss"
import SEO from "../components/seo"

function SocialIcon({ href, icon }) {
  return (
    <a target="_blank" rel="noreferrer" href={href}>
      <FontAwesomeIcon icon={icon} size="lg" />
    </a>
  )
}

SocialIcon.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.object,
}

function IndexPage() {
  const containerRef = useRef()
  const [isLeaving, setIsLeaving] = useState(false)

  useEffect(() => {
    gsap.from(containerRef.current.querySelectorAll(".socials svg"), {
      ease: Power2.easeOut,
      stagger: 0.1,
      y: 10,
      opacity: 0,
    })

    gsap.from(containerRef.current.querySelector("h1"), {
      ease: Power2.easeOut,
      xPercent: 100,
      opacity: 0,
    })

    gsap.from(containerRef.current.querySelector("p"), {
      ease: Power2.easeOut,
      xPercent: -100,
      opacity: 0,
    })
  }, [])

  if (typeof window !== "undefined") {
    window.onbeforeunload = function() {
      setIsLeaving(true)
    }
  }

  return (
    <div className="container" ref={containerRef}>
      <div className="content">
        <SEO />
        <div className="socials">
          <SocialIcon href="https://github.com/tomlschmidt" icon={faGithub} />
          <SocialIcon href="https://gitlab.com/tomlschmidt" icon={faGitlab} />
          <SocialIcon
            href="https://profiles.wordpress.org/tomlschmidt"
            icon={faWordpress}
          />
          <SocialIcon
            href="https://soundcloud.com/tomschmidtmusic"
            icon={faSoundcloud}
          />
          <SocialIcon
            href="https://www.youtube.com/channel/UCarAGJ7TIGpajLSjDbFfBfw"
            icon={faYoutube}
          />
          <SocialIcon href="https://unsplash.com/tomschmidt" icon={faUnsplash} />
          <SocialIcon
            href="https://www.last.fm/user/tomlschmidt"
            icon={faLastfm}
          />
          <SocialIcon
            href="https://music.apple.com/profile/tomlschmidt"
            icon={faApple}
          />
          <SocialIcon
            href="https://play.spotify.com/user/21fmc4cvf72mepowubby4vbvi"
            icon={faSpotify}
          />
          <SocialIcon
            href="https://tomschmidt.bandcamp.com"
            icon={faBandcamp}
          />
        </div>
        <h1>Tom Schmidt</h1>
        <p>
          {!isLeaving ? <>Hi</> : <>Bye</>}, I'm Tom. <br />
          I'm mainly a Web Developer but also Musician and 3d Artist.
        </p>
      </div>
    </div>
  )
}

export default IndexPage
